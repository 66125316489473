import React, { useState } from "react";
import SearchBar from "../SearchBar/SearchBar";
import { emojiMap } from "../../Constants/RandomProfile";
import GameView from "../GameView/GameView";
import PrimaryButton from "../Buttons/PrimaryButton";
import JSZip from "jszip";
import SubmissionCode from "../SubmissionCode/SubmissionCode";

const RoundResults = ({ round, noName }) => {
  const [showBattle, setShowBattle] = useState(-1);

  if (showBattle >= 0) {
    return (
      <div className="flex flex-col items-center gap-2">
        <GameView
          gameData={round[showBattle]}
          onClose={() => setShowBattle(-1)}
          noName={noName}
        />
      </div>
    );
  }

  return (
    <div className="overflow-hidden rounded-lg shadow-md bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark max-h-72">
      <div className="overflow-y-scroll max-h-72">
        <table className="min-w-full">
          <thead className="bg-surface-light dark:bg-surface-dark">
            <tr>
              <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                #
              </th>
              <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                Player 1
              </th>
              <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                Player 2
              </th>
              <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                Winner
              </th>
              <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                Watch
              </th>
            </tr>
          </thead>
          <tbody>
            {round && round.length > 0 ? (
              round.map((match, index) => (
                <tr
                  key={index}
                  className="border-b border-border-light dark:border-border-dark hover:bg-neutral-200 dark:hover:bg-neutral-700"
                >
                  <td className="px-4 py-3 text-sm sm:text-md text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                    {index + 1}
                  </td>
                  <td className="px-4 py-3 text-sm sm:text-md text-onSurface-light dark:text-onSurface-dark">
                    {noName ? match.player1Username : match.player1ModelName}
                  </td>
                  <td className="px-4 py-3 text-sm sm:text-md text-onSurface-light dark:text-onSurface-dark">
                    {noName ? match.player2Username : match.player2ModelName}
                  </td>
                  <td className="px-4 py-3 text-sm font-semibold text-green-500 sm:text-md">
                    {match.winner === 1 &&
                      (noName ? match.player1Username : match.player1ModelName)}
                    {match.winner === 2 &&
                      (noName ? match.player2Username : match.player2ModelName)}
                    {match.winner === 3 && "It's a Draw"}
                  </td>
                  <td className="px-4 py-3 text-sm sm:text-md">
                    <PrimaryButton
                      onClick={() => setShowBattle(index)}
                      children={"View Game"}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="py-4 text-sm text-center text-onSurface-light dark:text-onSurface-dark sm:text-md"
                >
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const FinalResults = ({ finalResults, searchTerm, noName, showCode }) => {
  const filteredResults = finalResults.filter(
    (player) =>
      player.details.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      player.details.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playerCode, setPlayerCode] = useState("");

  function parseSourceCode(m) {
    const binaryData = atob(m);
    const binaryArray = Uint8Array.from(binaryData, (char) =>
      char.charCodeAt(0)
    );

    const zip = new JSZip();
    zip
      .loadAsync(binaryArray)
      .then((zipContent) => {
        return zipContent.file("player.py").async("string");
      })
      .then((fileContent) => {
        setPlayerCode(fileContent);
      })
      .catch((error) => {
        console.error("Error extracting player.py:", error);
      });
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setPlayerCode("");
  };

  return (
    <div className="overflow-hidden rounded-lg shadow-md bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark max-h-72">
      <div className="overflow-y-scroll max-h-72">
        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-3/4 p-6">
              {/* Modal Header */}
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold text-gray-800">Submission</h2>
                <button
                  onClick={closeModal}
                  className="flex items-center mb-2 text-gray-400 hover:text-gray-600 transition text-2xl"
                >
                  &times;
                </button>
              </div>
              <div className="p-2 max-h-96 overflow-y-auto">
                <SubmissionCode code={playerCode} />
              </div>
            </div>
          </div>
        )}
        <table className="min-w-full">
          <thead className="bg-surface-light dark:bg-surface-dark">
            <tr>
              <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                Rank
              </th>
              {!noName && (
                <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                  Bot
                </th>
              )}
              <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                {noName ? "Player" : "Creator"}
              </th>
              <th className="px-4 py-3 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:text-md">
                Wins
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredResults.length > 0 ? (
              filteredResults.map((player, index) => (
                <tr
                  key={index}
                  className="cursor-pointer border-b border-border-light dark:border-border-dark hover:bg-neutral-200 dark:hover:bg-neutral-700"
                  onClick={() => {
                    if (showCode) {
                      parseSourceCode(player.details.code);
                      openModal();
                    }
                  }}
                >
                  <td className="px-4 py-3 text-sm sm:text-md text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                    {player.rank}
                  </td>
                  <td className="flex items-center gap-2 py-3 text-sm sm:text-md text-onSurface-light dark:text-onSurface-dark">
                    <span className="mr-2">
                      {emojiMap[player.details.icon]}
                    </span>
                    <span>
                      {noName ? player.details.username : player.details.name}
                    </span>
                  </td>
                  {!noName && (
                    <td className="px-4 py-3 text-sm sm:text-md text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
                      {player.details.username}
                    </td>
                  )}
                  <td className="px-4 py-3 text-sm font-semibold text-green-500 sm:text-md">
                    {player.wins}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  className="py-4 text-sm text-center text-onSurface-light dark:text-onSurface-dark sm:text-md"
                >
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TournamentResults = ({ rounds, finalResults, noName, showCode }) => {
  const [currentRound, setCurrentRound] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const handleRoundChange = (e) => setCurrentRound(Number(e.target.value));

  return (
    <div className="p-4">
      <div className="flex flex-col items-center justify-between mb-4 md:flex-row">
        <h1 className="mb-2 text-xl sm:text-2xl md:mb-0 text-onSurface-light dark:text-onSurface-dark">
          Results
        </h1>
        <div className="w-full md:w-1/2">
          <SearchBar
            placeholder={
              noName
                ? "Search by player..."
                : "Search by bot name or creator..."
            }
            setSearchTerm={setSearchTerm}
          />
        </div>
      </div>

      <div className="flex flex-col">
        {/* Final Results */}
        <div className="mb-6">
          <FinalResults
            finalResults={finalResults}
            searchTerm={searchTerm}
            noName={noName}
            showCode={showCode}
          />
        </div>

        {/* Round selection dropdown */}
        <div className="relative w-full mb-6">
          <select
            className="block w-full px-4 py-2 pr-10 leading-tight rounded appearance-none bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark text-onSurface-light dark:text-onSurface-dark focus:outline-none focus:bg-white focus:border-gray-500"
            value={currentRound}
            onChange={handleRoundChange}
          >
            {rounds.map((_, index) => (
              <option key={index} value={index}>
                Round {index + 1}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-onSurface-light dark:text-onSurface-dark">
            <svg
              className="w-4 h-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M5.516 7.548l4.493 4.493 4.493-4.493a1.21 1.21 0 111.71 1.71l-5.199 5.198a1.21 1.21 0 01-1.71 0L3.806 9.258a1.21 1.21 0 011.71-1.71z" />
            </svg>
          </div>
        </div>

        {/* Round Results */}
        <div>
          <RoundResults round={rounds[currentRound]} noName={noName} />
        </div>
      </div>
    </div>
  );
};

export default TournamentResults;
