import React, { useState, useEffect } from "react";
import ModelInfo from "../../Components/ModelInfo/ModelInfo";
import ProgressGraph from "../../Components/ProgressGraph/ProgressGraph";
import SubmissionCode from "../../Components/SubmissionCode/SubmissionCode";
import RecentBattleCard from "../../Components/RecentBattleCard/RecentBattleCard";
import Loading from "../../Components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import GameView from "../../Components/GameView/GameView";
import { GamesInfo } from "../../Constants/Games";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import { EditIcon } from "../../Constants/Icons";
import JSZip from "jszip";

function SubmissionPage({ user }) {
  const navigate = useNavigate();
  const { gameName, modelId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [model, setModel] = useState(null);
  const [history, setHistory] = useState([]);
  const [showBattle, setShowBattle] = useState(null);
  const [playerCode, setPlayerCode] = useState("");
  const [showGame, setShowGame] = useState(false);

  function parseSourceCode(m) {
    if (!m.files) {
      setPlayerCode("user is not authorized to view code");
      return;
    }
    const binaryData = atob(m.files);
    const binaryArray = Uint8Array.from(binaryData, (char) =>
      char.charCodeAt(0)
    );

    const zip = new JSZip();
    zip
      .loadAsync(binaryArray)
      .then((zipContent) => {
        return zipContent.file("player.py").async("string");
      })
      .then((fileContent) => {
        setPlayerCode(fileContent);
      })
      .catch((error) => {
        console.error("Error extracting player.py:", error);
      });
  }

  useEffect(() => {
    const getModelInfo = async () => {
      try {
        const token = await user.getIdToken();

        const info_res = await fetch(
          process.env.REACT_APP_endpointURL + "/model/content",
          {
            method: "POST",
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              game: gameName,
              modelId: modelId,
            }),
          }
        );
        if (!info_res.ok) {
          throw new Error(`HTTP error! status: ${info_res.status}`);
        }

        const info = await info_res.json();
        setModel(info);
        parseSourceCode(info);

        const history_res = await fetch(
          process.env.REACT_APP_endpointURL + "/model/history",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              game: gameName,
              modelId: modelId,
            }),
          }
        );
        if (!history_res.ok) {
          throw new Error(`HTTP error! status: ${history_res.status}`);
        }
        const model_history = await history_res.json();
        setHistory(model_history);
      } catch (error) {
        setError(true);
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    getModelInfo();
  }, [modelId]);

  if (showBattle !== null) {
    return (
      <div className="flex flex-col gap-2 items-center">
        <GameView
          gameData={showBattle}
          onClose={() => {
            setShowBattle(null);
          }}
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-screen mt-64">
        <Loading />
      </div>
    );
  }

  if (error) {
    return <NotFound />;
  }

  return (
    <div className="flex flex-col gap-12 px-4 md:px-10 mt-6">
      <div className="flex flex-col w-full gap-12 lg:flex-row">
        {/* Left column */}
        <div className="flex flex-col w-full gap-12 lg:w-2/3">
          <div className="flex flex-col w-full gap-6">
            <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
              {GamesInfo[gameName].title} Model Insights
            </p>
            <ModelInfo
              Rating={model.rating}
              Name={model.name}
              DateCreated={new Date(model.dateCreated).toLocaleDateString()}
              Creator={model.username}
            />
          </div>
          <div className="flex flex-col w-full gap-6">
            <div className="flex justify-between">
              {user && user.uid === model.uid && (
                <PrimaryButton
                  onClick={() => navigate(`/editor/${model.game}/${modelId}`)}
                >
                  <div className="flex items-center gap-3">
                    <EditIcon />
                    <p>Edit Model</p>
                  </div>
                </PrimaryButton>
              )}
              {user && gameName === "chess" && (
                <PrimaryButton
                  children={"Play"}
                  onClick={() => setShowGame(true)}
                />
              )}
            </div>
            {model.codebroke && (
              <p className="text-red-500">This code causes errors.</p>
            )}
            {showGame && (
              <div className="p-4 rounded-lg border-border-light dark:border-border-dark border-[1px] dark:bg-surfaceContain-dark bg-surfaceContain-light sm:flex sm:flex-col sm:items-start">
                <div className="justify-end">
                  <button
                    onClick={() => setShowGame(false)}
                    className="justify-end flex items-center gap-2 text-lg text-onSurface-light dark:text-onSurface-dark sm:text-xl hover:text-blue-600 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    Close
                  </button>
                </div>
                <div className="mt-4 w-full sm:mt-2">
                  {GamesInfo[gameName].playView("", model.modelId, user)}
                </div>
              </div>
            )}

            <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
              Code Submission
            </p>
            <div className="w-full overflow-x-auto text-onSurface-light dark:text-onSurface-dark">
              <SubmissionCode code={playerCode} />
            </div>
          </div>
        </div>

        {/* Right column */}
        <div className="flex flex-col w-full gap-12 lg:w-1/3 p-2">
          <div className="flex flex-col w-full gap-6">
            <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
              Rating Over Time
            </p>
            <div className="p-4 rounded-lg border-border-light dark:border-border-dark border-[1px] dark:bg-surfaceContain-dark bg-surfaceContain-light">
              <ProgressGraph
                history={history}
                modelId={modelId}
                dateCreated={model.dateCreated}
                initialRating={model.rating}
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-6">
            <p className="text-2xl font-medium text-onSurface-light dark:text-onSurface-dark">
              Rated Battles
            </p>
            {history ? (
              <div className="border-border-light overflow-clip border-[1px] rounded-lg dark:border-border-dark max-h-96 overflow-y-auto">
                {history.toReversed().map((battle, index) => (
                  <RecentBattleCard
                    key={index}
                    data={battle}
                    modelId={modelId}
                    setShowBattle={setShowBattle}
                  />
                ))}
              </div>
            ) : (
              <div className="text-center dark:text-white">
                No past battles...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmissionPage;
