import React, { useState } from "react";
import { emojiMap } from "../../Constants/RandomProfile";
import SearchBar from "../../Components/SearchBar/SearchBar";
import GameView from "../../Components/GameView/GameView";
import Loading from "../../Components/Loading/Loading";
import { TbInfoCircle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import ModelFilters from "../../Components/ModelFilter/ModelFilter";
import ErrorFlag from "../../Components/ErrorFlag/ErrorFlag";
import { useLocation } from "react-router-dom";
import ErrorPage from "../Error/Error";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";

const Battle = ({ models, setModelsLoading }) => {
  const location = useLocation();

  const [selectedGame, setSelectedGame] = useState(
    location.state?.initialGame || "connect4"
  );
  const [selectedBots, setSelectedBots] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showBattle, setShowBattle] = useState(false);
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  let navigate = useNavigate();

  const handleSelectBot = (bot) => {
    if (bot.codebroke) {
      return;
    }
    if (selectedBots.some((b) => b.modelId === bot.modelId)) {
      setSelectedBots(selectedBots.filter((b) => b.modelId !== bot.modelId));
    } else if (selectedBots.length < 2) {
      setSelectedBots([...selectedBots, bot]);
    }
  };

  const getGame = async () => {
    try {
      setLoading(true);

      const res = await fetch(
        process.env.REACT_APP_endpointURL + "/game/match",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            game: selectedGame,
            player1: selectedBots[0].modelId,
            player2: selectedBots[1].modelId,
          }),
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const result = await res.json();
      setGameData(result);
    } catch (error) {
      setError(true);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRankedGame = async () => {
    try {
      setLoading(true);
      const res = await fetch(
        process.env.REACT_APP_endpointURL + "/game/match/ranked",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            game: selectedGame,
            modelId: selectedBots[0].modelId,
          }),
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const result = await res.json();
      setGameData(result);
    } catch (error) {
      setError(true);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterSelect = (newGame) => {
    setSelectedGame(newGame);
    setSelectedBots([]);
  };

  if (loading) {
    return (
      <div className="h-screen p-12 mt-64">
        <Loading match={true} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center">
        <ErrorPage />
        <div className="flex justify-center items-center p-4">
          <PrimaryButton
            onClick={() => {
              setShowBattle(false);
              setGameData(null);
              setSearchTerm("");
              setError(false);
            }}
            children={"Back to Battle"}
          />
        </div>
      </div>
    );
  }

  if (showBattle) {
    return (
      <div className="flex flex-col items-center gap-2">
        <GameView
          gameData={gameData}
          onClose={() => {
            setShowBattle(false);
            setSearchTerm("");
            setModelsLoading((prev) => !prev);
          }}
        />
      </div>
    );
  }

  try {
    let gameModels = models?.get(selectedGame);
    let filteredBots = gameModels
      ? gameModels.filter(
          (bot) =>
            bot.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            bot.username.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

    return (
      <div className="flex flex-col w-full max-w-4xl gap-4 p-4 mx-auto mt-6 sm:p-6">
        <h2 className="text-xl font-bold text-center sm:text-2xl text-onSurface-light dark:text-onSurface-dark">
          Battle
        </h2>

        <ModelFilters
          selectedFilters={[selectedGame]}
          onFilterSelect={handleFilterSelect}
          singleSelect={true}
        />

        <SearchBar
          placeholder="Search by bot name or creator..."
          setSearchTerm={setSearchTerm}
        />

        <div className="overflow-hidden rounded-lg  bg-surfaceContain-light dark:bg-surfaceContain-dark border-[1px] border-border-light dark:border-border-dark">
          <div className="overflow-x-auto max-h-72">
            <table className="min-w-full">
              <thead className="bg-surface-light border-border-light dark:border-border-dark dark:bg-surface-dark border-b-[1px]">
                <tr>
                  <th className="px-4 py-2 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark">
                    Rank
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:py-3 sm:text-md">
                    Bot
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:py-3 sm:text-md">
                    Creator
                  </th>
                  <th className="px-4 py-2 text-sm font-semibold text-left text-onSurface-light dark:text-onSurface-dark sm:py-3 sm:text-md">
                    Rating
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredBots && filteredBots.length > 0 ? (
                  filteredBots.map((bot, index) => (
                    <tr
                      key={bot.modelId}
                      className={`cursor-pointer ${
                        selectedBots.some((b) => b.modelId === bot.modelId)
                          ? "bg-gray-200 dark:bg-neutral-800" // Light and dark mode colors
                          : "hover:bg-gray-100 dark:hover:bg-neutral-700" // Hover effect for both themes
                      }`}
                      onClick={() => handleSelectBot(bot)}
                    >
                      <td className="px-4 py-2 text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark sm:py-3 sm:text-md">
                        {bot.rank}
                      </td>
                      <td className="flex items-center gap-2 px-4 py-2 text-sm text-onSurface-light dark:text-onSurface-dark sm:py-3 sm:text-md">
                        <span className="mr-2">{emojiMap[bot.icon]}</span>
                        <span>{bot.name}</span>
                        <TbInfoCircle
                          className="text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark"
                          onClick={() =>
                            navigate(
                              `/submission/${selectedGame}/${bot.modelId}`
                            )
                          }
                        />
                      </td>
                      <td className="px-4 py-2 text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark sm:py-3 sm:text-md">
                        {bot.username}
                      </td>
                      <td className="px-4 py-2 text-sm text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark sm:py-3 sm:text-md">
                        <div className="flex items-center justify-center gap-2">
                          <span>{bot.rating}</span>
                          {bot.codebroke && (
                            <span className="mr-2">
                              <ErrorFlag />
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      className="py-4 text-sm text-center text-onSurface-light dark:text-onSurface-dark sm:text-md"
                    >
                      No bots found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {selectedBots.length > 0 && (
          <div className="flex items-center justify-center p-2 rounded-md">
            <p className="text-md sm:text-lg text-onSurface-light dark:text-onSurface-dark">
              {selectedBots[0].name +
                (selectedBots.length === 2
                  ? " VS " + selectedBots[1].name
                  : "")}
            </p>
          </div>
        )}

        <div className="flex justify-center">
          {selectedBots.length === 1 ? (
            <PrimaryButton
              onClick={() => {
                handleRankedGame();
                setShowBattle(true);
              }}
              children={"Play Ranked Game"}
            />
          ) : (
            <PrimaryButton
              onClick={() => {
                getGame();
                setShowBattle(true);
              }}
              children={"Start Battle"}
              disabled={selectedBots.length !== 2}
            />
          )}
        </div>
      </div>
    );
  } catch (err) {
    console.error("Error:", err);
    return <ErrorPage />;
  }
};

export default Battle;
