import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pinata from "../../Images/Logos/pinata.webp";
import { GamesInfo } from "../../Constants/Games";
import TournamentResults from "../../Components/Rounds/Results";
import rounds from "../../Constants/pinata2024rounds.json";
import finalResults from "../../Constants/pinata2024finals.json";
import { emojiMap } from "../../Constants/RandomProfile";

const TopWinners = () => {
  const winners = [
    {
      name: "Jacob Dimmitt",
      username: "@fomo_quandale_dingle430",
      emoji: 78,
      position: "1st",
      bgColor: "bg-gradient-to-r from-yellow-400 to-yellow-600",
    },
    {
      name: "Luke Zhu",
      username: "@better_call_saul_shimr952",
      emoji: 9,
      position: "2nd",
      bgColor: "bg-gradient-to-r from-blue-400 to-blue-600",
    },
    {
      name: "Madhavi Prakash",
      username: "@cg5_sin_city725",
      emoji: 65,
      position: "3rd",
      bgColor: "bg-gradient-to-r from-orange-400 to-orange-600",
    },
  ];

  return (
    <div className="flex flex-col md:flex-row justify-center space-x-8 m-1">
      {/* 2nd Place */}
      <div className="flex flex-col items-center">
        <div
          className={`${winners[1].bgColor} text-white flex justify-center items-center w-16 h-16 md:w-20 md:h-20 rounded-full text-3xl font-bold shadow-lg transform hover:scale-110 transition-transform duration-300 ease-out`}
        >
          {emojiMap[winners[1].emoji]}
        </div>
        <p className="mt-2 text-md md:text-lg text-gray-700 font-semibold">
          {winners[1].name}
        </p>
        <p className="text-sm md:text-gray-500 italic font-light">
          {winners[1].username}
        </p>
        <p className="text-sm md:text-md text-gray-500">
          {winners[1].position}
        </p>
      </div>

      {/* 1st Place */}
      <div className="flex flex-col items-center top-[-1px]">
        <div
          className={`${winners[0].bgColor} text-white flex justify-center items-center w-20 h-20 md:w-24 md:h-24 rounded-full text-4xl font-bold shadow-xl transform hover:scale-125 transition-transform duration-300 ease-out`}
        >
          {emojiMap[winners[0].emoji]}
        </div>
        <p className="mt-3 text-lg md:text-xl text-gray-700 font-bold">
          {winners[0].name}
        </p>
        <p className="text-sm md:text-gray-500 italic font-light">
          {winners[0].username}
        </p>
        <p className="text-md md:text-lg text-gray-500">
          {winners[0].position}
        </p>
      </div>

      {/* 3rd Place */}
      <div className="flex flex-col items-center">
        <div
          className={`${winners[2].bgColor} text-white flex justify-center items-center w-16 h-16 md:w-20 md:h-20 rounded-full text-3xl font-bold shadow-lg transform hover:scale-110 transition-transform duration-300 ease-out`}
        >
          {emojiMap[winners[2].emoji]}
        </div>
        <p className="mt-2 text-md md:text-lg text-gray-700 font-semibold">
          {winners[2].name}
        </p>
        <p className="text-sm md:text-gray-500 italic font-light">
          {winners[2].username}
        </p>
        <p className="text-sm md:text-md text-gray-500">
          {winners[2].position}
        </p>
      </div>
    </div>
  );
};

const PinataDetails = () => {
  return (
    <div className="flex flex-col gap-2">
      <div className="bg-gray-100 text-gray-900 p-8 rounded-lg shadow-lg dark:bg-gray-900 dark:text-white">
        <h2 className="text-3xl font-bold text-center mb-6">
          Build a Grandmaster Chess Bot
        </h2>
        <p className="text-center text-lg mb-4">
          Sponsored by{" "}
          <a
            href="https://pinata.cloud"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-400 font-semibold"
          >
            Pinata
          </a>
        </p>

        <p className="text-gray-700 mb-6 dark:text-gray-300">
          <span className="font-bold text-pink-500">brAIn rot</span> is thrilled
          to announce our first company-sponsored contest, a week-long battle of
          chess bots happening{" "}
          <span className="font-semibold text-pink-500 dark:text-blue-400">
            November 17 - November 23
          </span>
          ! This is your chance to develop and showcase your ultimate
          chess-playing algorithm in a head-to-head showdown.
        </p>

        <p className="text-gray-700 mb-6 dark:text-gray-300">
          With{" "}
          <a
            href="https://pinata.cloud"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-400 font-semibold"
          >
            Pinata
          </a>{" "}
          as our proud sponsor, we’ve made uploading your bot’s code easier and
          more secure than ever. Pinata’s decentralized file storage will host
          your code and you will be able to watch your bots play each other
          here.
        </p>

        <p className="text-gray-700 mb-6 dark:text-gray-300">
          Join us in making our first sponsored contest unforgettable!
          Submissions open Sunday November 17th at 8:00 AM CST and are due{" "}
          <span className="font-bold text-pink-500">
            Saturday November 23rd at 11:59 PM CST
          </span>
          .
        </p>
        <div className="flex flex-col text-center p-2 mt-8">
          <div className="text-4xl font-semibold text-onSurface-light dark:text-onSurface-dark">
            Prizes
          </div>
          <div className="flex flex-col sm:flex-row justify-between w-full mt-6 pb-16 gap-4 text-onSurface-light">
            <div className="flex flex-col w-full items-center bg-yellow-200 p-6 rounded-lg shadow-md">
              <div className="text-2xl font-semibold">1st Place</div>
              <div className="mt-4 text-xl">$1,000</div>
            </div>
            <div className="flex flex-col w-full items-center bg-gray-200 p-6 rounded-lg shadow-md">
              <div className="text-2xl font-semibold">2nd Place</div>
              <div className="mt-4 text-xl">$750</div>
            </div>
            <div className="flex flex-col w-full items-center bg-orange-200 p-6 rounded-lg shadow-md">
              <div className="text-2xl font-semibold">3rd Place</div>
              <div className="mt-4 text-xl">$250</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PinataChallenge = ({ user }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("details"); // Track active tab

  const info = {
    title: "Pinata Chess Challenge",
    startDate: "2024-11-17T1:00:00",
    endDate: "2024-11-23T23:59:00",
    game: GamesInfo.chess.image,
  };

  const finals = finalResults
    .sort((a, b) => b.wins - a.wins)
    .map((entry, index) => ({
      ...entry,
      rank: index + 1,
    }));

  const calculateTimeLeft = () => {
    const now = new Date();
    const startDate = new Date(info.startDate);
    const endDate = new Date(info.endDate);
    const timeToStart = startDate - now;
    const timeToEnd = endDate - now;

    if (timeToStart > 0) {
      return { status: "starting", timeLeft: timeToStart };
    } else if (timeToEnd > 0) {
      return { status: "active", timeLeft: timeToEnd };
    } else {
      return { status: "ended", timeLeft: null };
    }
  };

  const [timeStatus, setTimeStatus] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeStatus(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };

  return (
    <div className="flex flex-col items-center gap-6 px-8 py-8 md:px-10">
      <div className="flex flex-col w-full 2xl:max-w-screen-xl xl:max-w-screen-lg lg:max-w-screen-md gap-2">
        <div
          onClick={() => navigate("/contest")}
          className="select-none mb-4 text-onSurface-light dark:text-onSurface-dark cursor-pointer"
        >
          &#9664; Back to Contest
        </div>
        <div className="flex justify-between items-center">
          <div className="text-4xl font-bold text-onSurface-light dark:text-onSurface-dark">
            Pinata Chess Challenge
          </div>
          <img className="w-20" src={Pinata} />
        </div>

        {timeStatus.status === "starting" && (
          <div className="bg-yellow-100 text-yellow-700 py-4 px-6 mt-6 rounded-md">
            <span>The contest will start in </span>
            <span>{`${formatTime(timeStatus.timeLeft).days}d ${
              formatTime(timeStatus.timeLeft).hours
            }h ${formatTime(timeStatus.timeLeft).minutes}m ${
              formatTime(timeStatus.timeLeft).seconds
            }s`}</span>
          </div>
        )}

        {timeStatus.status === "active" && (
          <div className="bg-green-100 text-green-700 py-4 px-6 mt-6 rounded-md">
            <span>This contest will end in </span>
            <span>{`${formatTime(timeStatus.timeLeft).days}d ${
              formatTime(timeStatus.timeLeft).hours
            }h ${formatTime(timeStatus.timeLeft).minutes}m ${
              formatTime(timeStatus.timeLeft).seconds
            }s`}</span>
          </div>
        )}

        {timeStatus.status === "ended" && (
          <div className="bg-red-100 text-red-700 py-4 px-6 mt-6 rounded-md">
            <span>This contest has ended.</span>
          </div>
        )}

        {/* Tab Navigation */}
        <div className="mt-8 flex justify-center gap-6">
          <button
            className={`py-2 px-4 font-semibold ${
              activeTab === "details"
                ? "border-b-2 border-primary text-primary"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("details")}
          >
            Details
          </button>
          <button
            className={`py-2 px-4 font-semibold ${
              activeTab === "results"
                ? "border-b-2 border-primary text-primary"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab("results")}
          >
            Results
          </button>
        </div>

        {/* Tab Content */}
        <div className="mt-6">
          {activeTab === "details" && <PinataDetails />}
          {activeTab === "results" && (
            <div className="p-8 rounded-lg shadow-lg bg-gray-100 dark:bg-gray-900 text-onSurface-light dark:text-onSurface-dark">
              <TopWinners />
              <TournamentResults
                rounds={rounds}
                finalResults={finals}
                noName={true}
                showCode={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PinataChallenge;
