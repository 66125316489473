import React from "react";
import {
  Connect4GameCode,
  Connect4GameView,
  Connect4StarterCode,
  Connect4Description,
  Connect4ProblemStatement,
  Connect4VisualMap,
} from "./Connect4";
import {
  ChessGameView,
  ChessPlayView,
  ChessVisualMap,
  ChessDescription,
  ChessProblemStatement,
  ChessStarterCode,
  ChessGameCode,
} from "./Chess";
import {
  FillerGameCode,
  FillerGameView,
  FillerStarterCode,
  FillerDescription,
  FillerProblemStatement,
} from "./Filler";
import c4Img from "../Images/Game Covers/connect4_cover.png";
import chessImg from "../Images/Game Covers/chess_cover.png";
import othelloImg from "../Images/Game Covers/othello_cover.png";
import goImg from "../Images/Game Covers/go_cover.png";
import checkerImg from "../Images/Game Covers/checker_cover.png";
import fillerImg from "../Images/Game Covers/filler_cover.png";
import tronImg from "../Images/Game Covers/tron_cover.png";
import {
  OthelloDescription,
  OthelloGameCode,
  OthelloGameView,
  OthelloProblemStatement,
  OthelloStarterCode,
  OthelloVisualMap,
} from "./Othello";

export const GamesInfo = {
  connect4: {
    title: "Connect 4",
    description: "Create horizontal, vertical, or diagonal rows of four.",
    gameView: (gameData, setShowWinner) => {
      return (
        <Connect4GameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    visualMap: Connect4VisualMap,
    active: true,
    image: c4Img,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  chess: {
    title: "Chess",
    description: "Checkmate your opponent in this complex strategy game.",
    gameView: (gameData, setShowWinner) => {
      return (
        <ChessGameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    playView: (contestId, modelId, user) => {
      return (
        <ChessPlayView
          contestId={contestId}
          modelId={modelId}
          user={user}
        />
      );
    },
    visualMap: ChessVisualMap,
    active: true,
    image: chessImg,
    goal: ChessDescription,
    problemStatement: ChessProblemStatement,
    gameCode: ChessGameCode,
    starterCode: ChessStarterCode,
  },
  filler: {
    title: "Filler",
    description: "Fill the board with your color in this color matching game.",
    gameView: (gameData, setShowWinner) => {
      return (
        <FillerGameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    visualMap: {},
    active: true,
    image: fillerImg,
    goal: FillerDescription,
    problemStatement: FillerProblemStatement,
    gameCode: FillerGameCode,
    starterCode: FillerStarterCode,
  },
  othello: {
    title: "Othello",
    description:
      "Collect the most discs by flipping discs and trapping the opponent's pieces.",
    gameView: (gameData, setShowWinner) => {
      return (
        <OthelloGameView gameData={gameData} setShowWinner={setShowWinner} />
      );
    },
    visualMap: OthelloVisualMap,
    active: true,
    image: othelloImg,
    goal: OthelloDescription,
    problemStatement: OthelloProblemStatement,
    gameCode: OthelloGameCode,
    starterCode: OthelloStarterCode,
  },
  go: {
    title: "Go",
    description:
      "Place stones on a grid to surround more territory than the opponent.",
    gameView: (moves, gameData, setShowWinner) => {
      return (
        <Connect4GameView
          moves={moves}
          gameData={gameData}
          setShowWinner={setShowWinner}
        />
      );
    },
    visualMap: Connect4VisualMap,
    active: false,
    image: goImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  checkers: {
    title: "Checkers",
    description:
      "Elimate all opposing pieces by moving diagonally and jumping over opponents' pieces.",
    gameView: (moves, gameData, setShowWinner) => {
      return (
        <Connect4GameView
          moves={moves}
          gameData={gameData}
          setShowWinner={setShowWinner}
        />
      );
    },
    visualMap: Connect4VisualMap,
    active: false,
    image: checkerImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
  tron: {
    title: "Tron",
    description: "Control cars and avoid crashing into trails and walls.",
    gameView: (moves, gameData, setShowWinner) => {
      return (
        <Connect4GameView
          moves={moves}
          gameData={gameData}
          setShowWinner={setShowWinner}
        />
      );
    },
    visualMap: Connect4VisualMap,
    active: false,
    image: tronImg,
    goal: Connect4Description,
    problemStatement: Connect4ProblemStatement,
    gameCode: Connect4GameCode,
    starterCode: Connect4StarterCode,
  },
};
