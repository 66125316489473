import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  materialLight,
  materialDark,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import PrimaryButton from "../Buttons/PrimaryButton";

const SubmissionCode = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    });
  };

  return (
    <div className="relative w-full px-4 py-0 rounded-lg border-[1px] border-border-light bg-surfaceContain-light border-border-light dark:border-border-dark dark:bg-surfaceContain-dark">
      {/* Copy Button */}
      <div className="absolute top-2 right-4 px-2 py-1 z-10">
        <PrimaryButton
          onClick={handleCopy}
          children={copied ? "Copied!" : "Copy"}
        />
      </div>

      {/* Light Mode - Material Light Theme */}
      <div className="light:block dark:hidden">
        <SyntaxHighlighter
          language="python"
          style={materialLight}
          customStyle={{
            background: "none",
            fontSize: "0.875rem",
            color: "#2d2d2d",
          }}
          codeTagProps={{
            style: {
              background: "none",
            },
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>

      {/* Dark Mode - Material Dark Theme */}
      <div className="hidden dark:block">
        <SyntaxHighlighter
          language="python"
          style={materialDark}
          customStyle={{
            background: "none",
            fontSize: "0.875rem",
          }}
          codeTagProps={{
            style: {
              background: "none",
            },
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default SubmissionCode;
