import React, { useState, useEffect } from "react";
import { TbSunFilled } from "react-icons/tb";
import { TbMoonFilled } from "react-icons/tb";
import { emojiMap } from "../../Constants/RandomProfile";
import PrimaryButton from "../Buttons/PrimaryButton";

const ProfileInfo = ({ user, logout, name, icon }) => {
  // const [darkMode, setDarkMode] = useState(() => {
  //   const storedPreference = localStorage.getItem("darkMode");
  //   // If the user has a preference stored, use that. Otherwise, use the system preference.
  //   if (storedPreference) {
  //     return JSON.parse(storedPreference);
  //   } else {
  //     localStorage.setItem("darkMode", JSON.stringify(darkMode));
  //     return window.matchMedia("(prefers-color-scheme: dark)").matches;
  //   }
  // });

  // const toggleDarkMode = () => {
  //   setDarkMode(!darkMode);
  // };

  // useEffect(() => {
  //   if (darkMode) {
  //     document.documentElement.classList.add("dark");
  //     document.body.style.backgroundColor = "#1f2125";
  //   } else {
  //     document.documentElement.classList.remove("dark");
  //     document.body.style.backgroundColor = "#fcfcfc";
  //   }
  //   localStorage.setItem("darkMode", JSON.stringify(darkMode));
  // }, [darkMode]);

  return (
    <div className="flex flex-col sm:flex-row gap-6 items-center justify-between w-full border-[1px] px-12 py-8 rounded-lg bg-surfaceContain-light dark:bg-surfaceContain-dark border-border-light dark:border-border-dark">
      {/* Profile Icon */}
      <div className="flex items-center justify-center pt-3 rounded-full size-28">
        <p className="text-6xl">{emojiMap[icon]}</p>
      </div>

      {/* Profile Name */}
      <div className="flex flex-col">
        <p className="text-xs text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
          Name
        </p>
        <p className="font-medium text-onSurface-light dark:text-onSurface-dark">
          {name || "No display name available"}
        </p>
      </div>
      {/* Profile Email */}
      <div className="flex flex-col">
        <p className="text-xs text-onSurfaceSecond-light dark:text-onSurfaceSecond-dark">
          Email
        </p>
        <p className="font-medium text-onSurface-light dark:text-onSurface-dark">
          {user?.email || "No email available"}
        </p>
      </div>

      {/* Profile Buttons */}
      <div className="flex gap-6">
        {/* TODO: Add dark mode toggle here */}
        {/* <button
          onClick={toggleDarkMode}
          className="font-semibold box-border px-5 py-2 text-md font-medium dark:bg-surface-dark rounded-md text-onSurface-light dark:text-onSurface-dark border-b-2 border-r-2 bg-surface border-border dark:border-border-dark hover:translate-y-0.5 hover:border-b-2 hover:border-transparent dark:hover:border-b-2 dark:hover:border-transparent transition-all duration-300"
        >
          {darkMode ? (
            <div className="flex items-center gap-2">
              <TbSunFilled className="size-[14px]" />
              Light
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <TbMoonFilled className="size-[14px]" />
              Dark
            </div>
          )}
        </button> */}
        <PrimaryButton children={"Logout"} onClick={logout} />
      </div>
    </div>
  );
};

export default ProfileInfo;
